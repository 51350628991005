import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import data from "./data";
import Navbar from "./Navbar";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  font-size: 18px;
  @media screen and (max-width: 930px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  margin: 8px 0;
  &:nth-child(1) {
    margin-top: 15px;
  }
`;

const Text = styled.span`
  color: ${(e) => e.color || "black"};
  font-size: ${(e) => e.size || "19px"};
  font-family: "NotoSans";
  font-weight: ${(e) => e.bold || "400"};
  margin: ${(e) => e.margin || "0"};
`;

const Contents = styled.div`
  display: flex;
  padding: 0 30px;
  flex-direction: column;
`;

const ScrollContents = styled.div`
  flex: 1;
  margin: 30px 0;
  padding: 0 10%;
  @media screen and (max-width: 930px) {
    padding: 0 5%;
  }
`;

const DetailContents = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
`;

const DeepDetailContents = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  display: block;
  @media screen and (max-width: 930px) {
    width: 100%;
  }
  border-spacing: 0;
  margin: 20px 0 0 0;
  @media screen and (max-width: 930px) {
    display: none;
  }
`;

const TableTitle = styled.thead`
  & > tr > td {
    background: #f6f6f6;
    border-top: 0.5px solid #d3d3d3;
  }
  & > tr > td:nth-last-child(1) {
    border-right: none;
  }
`;

const TableBody = styled.tbody`
  & > tr > td {
    background: #fcfcfc;
  }
  & > tr > td:nth-last-child(1) {
    border: none;
    border-bottom: 0.5px solid #d3d3d3;
  }
`;

const Row = styled.tr`
  & > td {
    border: 0.5px solid #d3d3d3;
    border-left: none;
    border-top: none;
  }
`;

const Cell = styled.td`
  padding: 10px;
  @media screen and (max-width: 930px) {
    & > span {
      font-size: 13px;
    }
  }
`;

const ListGroup = styled.ul`
  @media screen and (min-width: 930px) {
    display: none;
  }
  padding: 0;
`;

const ListColumn = styled.li`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  list-style: none;
  background: ${(e) => e.bgColor || "#fcfcfc"};
  padding: 10px 0;
  border: ${(e) => (e.border ? "1px solid #D3D3D3" : "none")};
  border-left: none;
  border-right: none;
  align-items: center;
  justify-content: center;
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const SubTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  &:hover {
    & > div:nth-last-child(1) {
      width: 100%;
    }
  }
  cursor: pointer;
`;

const SubTextLine = styled.div`
  transition: all 0.3s ease;
  width: ${(e) => (e.underLine ? "100%" : "0%")};
  height: 3px;
  background: gray;
`;

const LineSplit = styled.div`
  display: flex;
  width: 2px;
  height: 20px;
  background: rgba(0, 0, 0, 0.4);
`;

const PrivacyPolicy = () => {
  const params = useParams();
  const [subTitleIndex, setSubTitleIndex] = useState(0);

  const Navigation = useNavigate();

  const paths = [
    {
      name: "privacypolicy",
    },
    {
      name: "member",
    },
    {
      name: "customerapp",
    },
    {
      name: "fammerapp",
    },
    {
      name: "secession",
    },
  ];

  const checkParamsId = (e) => {
    let boolean = false;
    if (e?.toString()?.replace(/ /g, "") != "" && e != "" && e) {
      paths.forEach((r) => {
        if (r.name == e.toLowerCase().toString()) {
          boolean = true;
          return;
        }
      });
    }
    return boolean;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  if (!checkParamsId(params?.id)) {
    setTimeout(() => {
      Navigation("/");
    }, 300);
    return null;
  }

  const fontSize = {
    big: "1.8em",
    title: "1.3em",
    default: "0.9em",
    tabMenu: "1.3em",
  };

  const returnTable = (e) => {
    let title = e?.title?.split("|");
    let contents = e?.contents;
    return (
      <>
        <Table>
          <TableTitle>
            <Row>
              {title?.map((r) => (
                <Cell>
                  <Text size={fontSize.default}>{r}</Text>
                </Cell>
              ))}
            </Row>
          </TableTitle>
          <TableBody>
            {contents?.map((r) => (
              <>{returnTableContents(r)}</>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };

  const returnTableContents = (e) => {
    let contents = e?.text?.split("|");
    return (
      <Row>
        {contents?.map((r, index) => (
          <Cell>
            <Text size={fontSize.default}>{r}</Text>
          </Cell>
        ))}
      </Row>
    );
  };

  const returnList = (e) => {
    return (
      <ListGroup>
        {e?.map((r) => (
          <>
            <ListColumn bgColor="#F6F6F6" border="true">
              <Text size={fontSize.default}>{r?.title}</Text>
            </ListColumn>
            {r?.content?.map((f) => (
              <ListColumn>
                <Text size={fontSize.default}>{f?.text}</Text>
              </ListColumn>
            ))}
          </>
        ))}
      </ListGroup>
    );
  };

  const returnData = (data, number, titleIndex) => {
    if (number == 2) {
      return data[number][0]?.subTitle[titleIndex]?.data;
    } else {
      return data[number];
    }
  };

  return (
    <Container>
      <ScrollContents>
        {returnData(data, params?.id, subTitleIndex).map((r) => (
          <Contents>
            <Title>
              {r?.bigTitle && (
                <Text size={fontSize.big} bold="bold" margin="0 0 10px 0">
                  {r?.bigTitle}
                </Text>
              )}
              <Text size={fontSize.title} bold="bold">
                {r?.title}
              </Text>
            </Title>
            {r?.content?.map((f, index) => (
              <>
                <Text size={fontSize.default}>{f?.text}</Text>
                <DetailContents>
                  {f?.detail?.map((rf, index) => (
                    <Text size={fontSize.default} margin="2px 0">
                      {rf?.text}
                    </Text>
                  ))}
                  {f?.table && returnTable(f?.table)}
                </DetailContents>
                {f?.list && returnList(f?.list)}
                <DeepDetailContents>
                  {f?.deepDetail?.map((dt, index) => (
                    <Text size={fontSize.default} margin="2px 0">
                      {dt?.text}
                    </Text>
                  ))}
                </DeepDetailContents>
              </>
            ))}
          </Contents>
        ))}
      </ScrollContents>
    </Container>
  );
};
export default PrivacyPolicy;
