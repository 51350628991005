import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { images } from "../Images";
import { isMobile } from "react-device-detect";
import { Text } from "../../../Components/Components";
import { data } from "./topbarData";
import { is_Browser, check_Tablet } from "../../../Util/Util";
import { animateScroll as scroll } from "react-scroll";

const Parents = styled.div`
  width: 100%;
  background: ${(e) => e.whiteMode || "transparent"};
  position: fixed;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  box-shadow: ${(e) => e.shadow || "transparent"};
  top: 0;
`;
const Contents = styled.div`
  width: 66%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
`;
const Images = styled.img`
  width: 20%;
  height: 22%;
  min-width: 60px;
  min-height: 60px;
`;
const MenuParents = styled.div`
  display: flex;
  width: 40%;
  min-width: 400px;
  align-items: center;
  justify-content: space-between;
`;
const MenuText = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 10px 0;
  transition: all 1s ease forwards;
  ${(e) =>
    e.boder
      ? css`
          border-bottom: 3px solid
            ${(e) =>
              e.mode === true
                ? "rgba(255, 255, 255, 0.6)"
                : "rgba(0, 0, 0, 0.6)"};
        `
      : null}
`;
const AlignParents = styled.div`
  display: flex;
  width: 50%;
`;

let heightDevice = window.innerHeight;

const TopBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [focus, setFocus] = useState("introduce");
  const [whiteMode, setWhiteMode] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    scrollEvents();
    resizes();
    heightDevice = window.innerHeight;
    return () => {
      window.removeEventListener("scroll", mobileHandler);
      window.removeEventListener("scroll", wheelHandler, {
        passive: false,
      });
      window.removeEventListener("resize", resizes);
    };
  }, []);

  useEffect(() => {
    if (focus === "introduce") {
      setWhiteMode(true);
    } else {
      setWhiteMode(false);
    }
  }, [focus]);
  const scrollEvents = () => {
    window.addEventListener("scroll", wheelHandler, {
      passive: false,
    });
    window.addEventListener("resize", resizes);
  };

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  const resizes = () => {
    let size = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${size}px`);
  };

  let introduce = "";
  let foodPlan = "";
  let technology = "";
  let ourCuture = "";
  let footered = "";
  let ticking = false;
  const wheelHandler = (e) => {
    if (!ticking) {
      ticking = true;
      requestAnimationFrame(() => {
        let tabbarHeight = document.getElementById("tabbar").clientHeight;
        introduce =
          document.getElementById("foodPlan").offsetTop - tabbarHeight;
        foodPlan =
          document.getElementById("foodPlan").offsetTop - tabbarHeight * 2;
        technology =
          document.getElementById("technology").offsetTop - tabbarHeight * 3;
        ourCuture =
          document.getElementById("ourCuture").offsetTop - tabbarHeight * 3;
        footered =
          document.getElementById("footered").offsetTop - tabbarHeight * 3;
        let scrollLocation = document.documentElement.scrollTop;

        if (scrollLocation >= foodPlan + tabbarHeight) {
          setWhiteMode(false);
        } else {
          setWhiteMode(true);
        }

        if (scrollLocation < foodPlan + tabbarHeight) {
          setFocus("introduce");
        } else if (scrollLocation < technology) {
          setFocus("foodPlan");
        } else if (scrollLocation < ourCuture) {
          setFocus("technology");
        } else if (scrollLocation < footered) {
          setFocus("ourCuture");
        } else {
          setFocus("footered");
        }
        ticking = false;
      });
    }
  };

  const mobileHandler = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    let scrollLocation = document.documentElement.scrollTop;
    let heightDevice = window.innerHeight;
    setScrollPosition(scrollLocation);
    heightDevice = window.innerHeight;
    // if (scrollLocation < heightDevice * 1) {
    //   setWhiteMode(true);
    //   setFocus("foodPlan");
    // } else {
    //   setWhiteMode(false);
    //   setFocus("introduce");
    // }
    // if (scrollLocation < heightDevice * 1) {
    //   setFocus(0);
    // } else if (scrollLocation <= heightDevice * 2) {
    //   setFocus(1);
    // } else if (scrollLocation < heightDevice * 3) {
    //   setFocus(1);
    // } else if (scrollLocation < heightDevice * 4) {
    //   setFocus(3);
    // } else if (scrollLocation < heightDevice * 5) {
    //   setFocus(4);
    // } else {
    //   setFocus(5);
    // }
  };

  const moveScroll = (e) => {
    let heightItem = document.getElementById(e).offsetTop;
    let menuHeight = document.getElementById("tabbar").offsetHeight;
    let marginMenu = 0;
    if (e == "ourCuture") {
      if (check_Tablet()) {
        marginMenu = menuHeight * 1;
      } else {
        marginMenu = menuHeight * 1;
      }
    } else if (e == "technology") {
      marginMenu = menuHeight * 1;
    } else if (e == "footered") {
      marginMenu = menuHeight * 1;
    } else if (e == "foodPlan" && check_Tablet()) {
      marginMenu = menuHeight * 0.5;
    }
    scroll.scrollTo(heightItem - marginMenu);
  };

  return (
    <Parents
      whiteMode={whiteMode ? "" : "white"}
      shadow={whiteMode ? "" : "0.5px 0.5px 2px gray"}
      id="tabbar"
    >
      <Contents>
        <AlignParents>
          <Images src={whiteMode ? images.logo : images.logo} />
        </AlignParents>
        <MenuParents>
          {data.map((r) => (
            <MenuText
              key={`topbar${r.id}`}
              boder={focus === r.id}
              mode={whiteMode}
              onClick={() => {
                moveScroll(r.id);
              }}
            >
              <Text
                size="1em"
                color={whiteMode ? "white" : "black"}
                cursor="pointer"
              >
                {r.name}
              </Text>
            </MenuText>
          ))}
        </MenuParents>
      </Contents>
    </Parents>
  );
};
export default TopBar;
