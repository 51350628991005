import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { images } from "../../Images";
import { slidedata } from "./data";
import { Text } from "../../../../Components/Components";

const Parents = styled.div`
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 0.5em;
`;
const LeftButton = styled.img`
  width: 3%;
  height: 9%;
  position: absolute;
  cursor: pointer;
  left: 0;
  margin: 0 4%;
  z-index: 3;
  min-width: 50px;
  min-height: 50px;
`;
const RightButton = styled.img`
  transform: rotateY(180deg);
  width: 4%;
  height: 9%;
  cursor: pointer;
  position: absolute;
  right: 0;
  min-width: 50px;
  min-height: 50px;
  margin: 0 4%;
  z-index: 3;
`;
const TopContents = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
`;
const BottomContents = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SlideGroup = styled.div`
  width: 73.5%;
  height: 90%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
`;
const AlignContents = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
const Contents = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: ${(e) => e.move || 0};
  transition: all 1s ease;
`;
const SlideParents = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;
const Title = styled.div`
  width: 73.5%;
  display: flex;
  margin-bottom: 15px;
  box-sizing: border-box;
`;
const TextAlign = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  word-break: break-all;
  margin: ${(e) => e.margin || "0"};
  white-space: pre-wrap;
`;
const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
const AlignParents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
`;
const BottomSlideButton = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SlideElipse = styled.div`
  border-radius: 100%;
  border: 1px solid black;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  transition: all 0.5s ease;
  ${(e) =>
    e.select &&
    css`
      border: 1px solid #2f80ed;
      background: #2f80ed;
      width: 45px;
      border-radius: 10px;
    `}
`;
const AlignChild = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const Slide = () => {
  const [select, setSelect] = useState(0);
  // const timeRef = useRef(null);
  // useEffect(() => {
  //   TimeStart();
  //   return () => {
  //     clearInterval(timeRef.current);
  //   };
  // }, []);
  // const TimeStart = () => {
  //   let sec = 0;
  //   timeRef.current = setInterval(() => {
  //     if (sec == slidedata.length - 1) {
  //       sec = 0;
  //     } else if (sec < slidedata.length - 1) {
  //       sec++;
  //     }

  //     setSelect(sec);
  //   }, 2000);
  // };

  const moveEvent = (t) => {
    if (t == "go") {
      if (select == slidedata.length - 1) {
        setSelect(0);
      } else {
        setSelect(select + 1);
      }
    } else if (t == "back") {
      if (select == 0) {
        setSelect(slidedata.length - 1);
      } else {
        setSelect(select - 1);
      }
    }
  };
  const moveButtonEvent = (n) => {
    setSelect(n);
  };

  return (
    <Parents>
      <AlignChild>
        <SlideParents>
          <LeftButton
            src={images.slide_button}
            onClick={() => {
              moveEvent("back");
            }}
          />
          <AlignParents>
            <SlideGroup>
              <Title>
                <Text color="#2F80ED" bold="bold" size="1.25em">
                  OUR CULTURE
                </Text>
              </Title>
              {slidedata.map((r, index) => (
                <Contents
                  move={`translateX(${-100 * select}%)`}
                  key={`title${index}`}
                >
                  <AlignContents>
                    <TopContents>
                      <TextAlign>
                        {r.title.map((t, i) => (
                          <Text
                            key={`ourcuture${i}`}
                            size="1.5em"
                            bold="bold"
                            color="#333333"
                            margin="0 0 3px 0"
                            wordSpacing="0.1em"
                            lineHeight="1.1em"
                          >
                            {t.text}
                          </Text>
                        ))}
                      </TextAlign>
                      <TextAlign margin="0 0 0 15%">
                        {r.contents.map((r) => (
                          <Text
                            key={r.text}
                            lineHeight="1.3em"
                            size="0.9em"
                            color="#333333"
                            margin="0 0 3px 0"
                            key={r.text}
                            wordSpacing="0.1em"
                          >
                            {r.text}
                          </Text>
                        ))}
                      </TextAlign>
                    </TopContents>
                    <BottomContents>
                      <Image src={r.image} />
                    </BottomContents>
                  </AlignContents>
                </Contents>
              ))}
            </SlideGroup>
            <BottomSlideButton>
              {slidedata.map((r, index) => (
                <SlideElipse
                  key={`elipse${index}`}
                  select={select == index}
                  onClick={() => {
                    moveButtonEvent(index);
                  }}
                />
              ))}
            </BottomSlideButton>
          </AlignParents>
          <RightButton
            src={images.slide_button}
            onClick={() => {
              moveEvent("go");
            }}
          />
        </SlideParents>
      </AlignChild>
    </Parents>
  );
};
export default Slide;
