import React from "react";
import Introduce from "./Introduce";
import styled, { css } from "styled-components";
import FoodPlan from "./FoodPlan";
import Technology from "./Technology/Technology ";
import OurCuture from "./OurCuture/OurCuture";
import BottomInfo from "./BottomInfo/BottomInfo";
import TechnologyMobile from "./Technology/TechnologyMobile";
import { useGetMobile } from "../../../Util/Util";
import "./iegrid.css";

const Parents = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${(e) =>
    e.mobile &&
    css`
      width: 100%;
      height: 100%;
    `}
  margin: 0 auto;
`;

const Contents = () => {
  return (
    <Parents mobile={useGetMobile()}>
      <Introduce />
      <FoodPlan />
      {useGetMobile() ? <TechnologyMobile /> : <Technology />}
      <OurCuture />
      <BottomInfo />
    </Parents>
  );
};
export default Contents;
