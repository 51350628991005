import React from "react";
import {
  Child,
  ChildWidth,
  ImageParents,
  BottomBlack,
  TextGroup,
  TextInfo,
  Rows,
} from "../MobileSlide";
import { Text } from "../../../../../Components/Components";

function One() {
  return (
    <Child>
      <ChildWidth>
        <ImageParents image={require("../../../image/directstore.jpg")}>
          <BottomBlack>
            <TextGroup>
              <Text color="white" size="1.1em" bold="bold" margin="0 0 0.3em 0">
                로컬푸드 10년의 노하우를 결집한
              </Text>
              <Text color="white" size="2em" bold="bold">
                직매장 운영 시스템
              </Text>
            </TextGroup>
          </BottomBlack>
        </ImageParents>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              농가와 상품 관리, 판매, 분석, 정산 등&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                로컬푸드에 특화된 매장 관리 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              신뢰할 수 있는 먹거리, 안심할수 있는 먹거리를 위한&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                교육 및 인증 관리 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em" bold="bold">
              재고관리, 이벤트 기능 등&nbsp;
              <Text size="1em" color="#474747" bold="400">
                등 로컬푸드 직매장의 원할한 운영을 돕는 부가 기능들
              </Text>
            </Text>
          </Rows>
        </TextInfo>
      </ChildWidth>
    </Child>
  );
}

function Two() {
  return (
    <Child>
      <ChildWidth>
        <ImageParents image={require("../../../image/cateringcenter.png")}>
          <BottomBlack>
            <TextGroup>
              <Text color="white" size="1.1em" bold="bold" margin="0 0 0.3em 0">
                국내 유일 로컬푸드 기반
              </Text>
              <Text color="white" size="2em" bold="bold">
                급식센터 운영 시스템
              </Text>
            </TextGroup>
          </BottomBlack>
        </ImageParents>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              발주나 클레임을 등록 ,관리하는&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                온라인 주문 시스템
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              유통 단계의 변화에도 유연하게, 간편하게 대응할 수 있는&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                수주 위임 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              학교급식과 공공급식부터 도농상생 급식까지&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                모든 급식에서 운영 가능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              농가 · 상품 관리에서 발주, 수주까지,&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                간편하게 개선한 수발주 관리 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
      </ChildWidth>
    </Child>
  );
}

function Three() {
  return (
    <Child>
      <ChildWidth>
        <ImageParents image={require("../../../image/plannedproduction.png")}>
          <BottomBlack>
            <TextGroup>
              <Text color="white" size="1.1em" bold="bold" margin="0 0 0.3em 0">
                성공적인 푸드플랜을 위한 통합데이터 기반
              </Text>
              <Text color="white" size="2em" bold="bold">
                기획생산 시스템
              </Text>
            </TextGroup>
          </BottomBlack>
        </ImageParents>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              직매장 및 급식센터 정보를&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                하나로 통합
              </Text>
              하여 소비 분석할 수 있는&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                정보 분석도구
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              경작 현황에서&nbsp;
              <Text size="1em" bold="bold" color="#474747">
                예상 생산량 · 출하량까지&nbsp;
              </Text>
              한 흐름으로 관리하는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                기획 생산 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              국내 최다 농산물 유통 채널&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                “농협”과 연계&nbsp;
              </Text>
              <Text size="0.8em" color="gray">
                (지원 예정)
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              여러 지역의 푸드플랜들이 하나로 연결되어 확장하는,&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                광역 푸드플랜&nbsp;
              </Text>
              <Text size="0.8em" color="gray">
                (지원 예정)
              </Text>
            </Text>
          </Rows>
        </TextInfo>
      </ChildWidth>
    </Child>
  );
}

function Four() {
  return (
    <Child>
      <ChildWidth>
        <ImageParents image={require("../../../image/dataanalysis.png")}>
          <BottomBlack>
            <TextGroup>
              <Text color="white" size="1.1em" bold="bold" margin="0 0 0.3em 0">
                복잡한 운영 자료들도 클릭만으로 만들어내는
              </Text>
              <Text color="white" size="2em" bold="bold">
                분석 & 보고 서비스
              </Text>
            </TextGroup>
          </BottomBlack>
        </ImageParents>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              서식을 원하는 형태의 서식을&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                구성
              </Text>
              하고 필요한 정보만&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                추출
              </Text>
              하는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                정보 분석 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              <Text size="1em" bold="bold">
                클릭 한번
              </Text>
              으로 미리 만든 서식을 적용하고 그 서식 그대로&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                공유
              </Text>
              할 수 있는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                서식 관리 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              보이는 화면 그대로 엑셀로 보는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                외부 공유 기능
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              도표와 그래프로 편하게 보는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                시각화 기능&nbsp;
              </Text>
              <Text size="0.8em" color="gray">
                (지원 예정)
              </Text>
            </Text>
          </Rows>
        </TextInfo>
      </ChildWidth>
    </Child>
  );
}

function Five() {
  return (
    <Child>
      <ChildWidth>
        <ImageParents image={require("../../../image/mobileservice.png")}>
          <BottomBlack>
            <TextGroup>
              <Text color="white" size="1.1em" bold="bold" margin="0 0 0.3em 0">
                언제든지, 어디서든지
              </Text>
              <Text color="white" size="2em" bold="bold">
                모바일 서비스
              </Text>
            </TextGroup>
          </BottomBlack>
        </ImageParents>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              외부에서도 업무를 진행할 수 있는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                매니저용 APP
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              판매 & 수주 정보를 실시간 조회하는&nbsp;
              <Text size="1em" bold="bold">
                농가 APP
              </Text>
            </Text>
          </Rows>
        </TextInfo>
        <TextInfo>
          <Rows>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              -&nbsp;
            </Text>
            <Text size="1.1em" color="#474747" lineHeight="1.4em">
              로컬푸드 활용을 돕는&nbsp;
              <Text size="1em" color="#474747" bold="bold">
                소비자용 APP&nbsp;
              </Text>
              <Text size="0.8em" color="gray">
                (지원 예정)
              </Text>
            </Text>
          </Rows>
        </TextInfo>
      </ChildWidth>
    </Child>
  );
}

export default function MobileSlideItem() {
  return (
    <>
      <One />
      <Two />
      <Three />
      <Four />
      <Five />
    </>
  );
}
