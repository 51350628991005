import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Text } from "../../../Components/Components";
import { images } from "../Images";
import { is_Mobile } from "../../../Util/Util";
import { moblieData } from "./topbarData";
import { useRecoilState } from "recoil";
import { mobiles } from "../../../Recoil/recoil";
import { animateScroll as scroll } from "react-scroll";
import { keyframes } from "styled-components";

const Parents = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
  background: ${(e) => e.background || "transparent"};
  /* ${(e) =>
    e.exit &&
    css`
      flex-direction: column;
      height: calc(var(--vh, 1vh) * 100);
    `}; */
  box-shadow: ${(e) => e.shadow || "transparent"};
`;
const AlignContents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  background: ${(e) => e.background || "transparent"};
`;
const Contents = styled.div`
  width: 50%;
  display: flex;
  padding: 5px 0;
  &:nth-child(1) {
    align-items: center;
    justify-content: flex-start;
  }
  &:nth-last-child(1) {
    align-items: center;
    justify-content: flex-end;
  }
`;
const Image = styled.img`
  width: ${(e) => e.width || "110px"};
  height: ${(e) => e.height || "50px"};
  margin: ${(e) => e.margin || "0"};
`;
const MenuAnimation = keyframes`
  0%{
    top:-30%;
  }
  100%{
    top:0;
  }
`;
const MenuBackground = styled.div`
  width: 100%;
  height: 100vh;
  background: transparent;
  overflow: hidden;
  position: relative;
`;

const Menu = styled.div`
  width: 100%;
  animation: ${MenuAnimation} 0.7s 1 ease forwards;
  position: absolute;
  height: -30%;
  /* top: -100%; */
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
`;
const MenuChild = styled.div`
  display: flex;
  padding: 1em 1.5em;
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  &:nth-last-child(1) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  }
`;
const ExitTopAlign = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 3em;
`;
const TextList = styled.div`
  display: flex;
`;

const MobileTopBar = () => {
  const [whiteMode, setWhiteMode] = useState(true);
  const [exitMode, setExitMode] = useState(false);
  const [select, setSelect] = useState("introduce");

  useEffect(() => {
    mobileSetVh();
    window.scrollTo(0, 0);
  }, []);

  // 메뉴 클릭 시 스크롤 제한
  useEffect(() => {
    if (exitMode) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "";
    }
    document.documentElement.style.setProperty(
      "--mobileHeight",
      `${window.innerHeight * 0.01}px`
    );
    getExitPosition();
    window.addEventListener("scroll", scrollEvent, { passive: false });
    return () => {
      window.removeEventListener("scroll", scrollEvent, { passive: false });
    };
  }, [exitMode]);

  // const tableOrientation = () => {
  //   setOrientation(is_Mobile());
  //   mobileSetVh();
  //   return;
  // };
  const getExitPosition = () => {
    let whiteHeight = document.getElementById("foodPlan").offsetTop;
    let introduce = document.getElementById("introduce").offsetTop;
    let mobiletechnology =
      document.getElementById("mobiletechnology").offsetTop;
    let mobileOurCuture =
      document.getElementById("mobileOurCuture").offsetTop * 0.8;
    let footered = document.getElementById("footered").offsetTop;
    let tabbarHeight = document.getElementById("tabbar").offsetHeight;
    let scrollLocation = document.documentElement.scrollTop;
    if (scrollLocation < whiteHeight - tabbarHeight) {
      setSelect("introduce");
    } else if (scrollLocation < mobiletechnology - tabbarHeight) {
      setSelect("foodPlan");
    } else if (scrollLocation < mobileOurCuture - tabbarHeight) {
      setSelect("mobiletechnology");
    } else if (scrollLocation < footered - tabbarHeight * 3) {
      setSelect("mobileOurCuture");
    } else {
      setSelect("footered");
    }
  };
  const mobileSetVh = () => {
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    if (is_Mobile()) {
      document.documentElement.style.setProperty("--vw", `${vw}px`);
    }
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // window.addEventListener("orientationchange", () => {
  //   mobileSetVh();
  //   tableOrientation();
  // });

  // ios 전용 크기 번경
  // window.addEventListener("touchend", () => {
  //   mobileSetVh();
  // });
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  const scrollEvent = (e) => {
    let whiteHeight = document.getElementById("foodPlan").offsetTop;

    if (whiteMode) {
      e.preventDefault();
      e.stopPropagation();
    }
    let scrollLocation = document.documentElement.scrollTop;
    if (scrollLocation >= whiteHeight) {
      setWhiteMode(false);
    } else if (scrollLocation <= whiteHeight) {
      setWhiteMode(true);
    }
  };

  const scrollMove = (e) => {
    let ItemY = document.getElementById(e).offsetTop;
    let marginMenu = document.getElementById("tabbar").offsetHeight;
    let margins = 0;
    setExitMode(false);
    if (e === "mobiletechnology") {
      margins = marginMenu;
    } else if (e === "mobileOurCuture") {
      margins = marginMenu;
    }
    scroll.scrollTo(ItemY - margins);
  };
  const outFoucs = (e) => {
    if (e.target === e.currentTarget) {
      setExitMode(false);
    }
  };

  return (
    <Parents
      background={!whiteMode && !exitMode && "white"}
      shadow={whiteMode ? "" : "0.5px 0.5px 2px gray"}
    >
      <AlignContents background={exitMode && !whiteMode && "white"} id="tabbar">
        <Contents>
          <Image src={images.logo} margin="0 0 0 1.5em" />
        </Contents>
        <Contents>
          <Image
            margin="0 1.5em 0 0"
            src={whiteMode ? images.menu_transparent : images.menu}
            width="25px"
            height="25px"
            onClick={() => {
              setExitMode(!exitMode);
            }}
          />
        </Contents>
      </AlignContents>
      {exitMode && (
        <MenuBackground
          onClick={(e) => {
            outFoucs(e);
          }}
        >
          <Menu>
            {moblieData.map((r) => (
              <MenuChild
                key={r.id}
                selected={r.id == select}
                onClick={() => {
                  scrollMove(r.id);
                }}
              >
                <Text
                  color={r.id == select ? "#2F80ED" : "rgba(0,0,0,0.7)"}
                  size="1.3em"
                  bold={r.id == select ? "bold" : "400"}
                >
                  {r.name}
                </Text>
              </MenuChild>
            ))}
          </Menu>
        </MenuBackground>
      )}
    </Parents>
  );
};
export default MobileTopBar;
