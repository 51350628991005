import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { images } from "../../Images";
import { Text } from "../../../../Components/Components";
import { data } from "./data";
import { check_Tablet, is_Browser } from "../../../../Util/Util";

const Parents = styled.div`
  width: 100%;
  height: 81vh;
  height: ${check_Tablet()
    ? "calc(var(--vh, 1vh) * 90)"
    : "calc(var(--vh, 1vh) * 82)"};
  background: url(${images.technology}) no-repeat;
  background-size: cover;
`;
const BackgroundContents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(54, 77, 101, 0.7);
`;
const ChildTopBackground = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 66%;
  padding: ${check_Tablet() ? "1em" : "2em"} 0;
  margin: 0;
  box-sizing: border-box;
`;
const ChildBottomBackground = styled.div`
  background: rgba(55, 74, 94, 0.6);
  width: 100%;
  height: ${(e) => e.height || "90%"};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const ServiceParents = styled.div`
  width: 60%;
  height: 85%;
  font-size: calc(2vmin);
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 2px 2px 15px black;
  @media screen and (max-width: 1387px) {
    align-items: unset;
  }
  @media screen and (max-height: 730px) {
    align-items: unset;
  }
`;
const ServiceGroup = styled.div`
  width: 80%;
  margin-left: 8%;
  box-sizing: border-box;
`;
const ServiceChild = styled.div`
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
  &:nth-last-child(1) > div > div {
    width: 100%;
  }
`;
const ServiceContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  word-break: break-all;
  & > span:nth-last-child(1) {
    margin-bottom: 20px;
  }
`;
const Image = styled.img`
  width: 12%;
  min-width: 40px;
  height: 12%;
  min-height: 40px;
`;
const TextGroup = styled.div`
  width: 88%;
  display: flex;
  flex-direction: column;
`;
const Grid = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px 10px;
  box-sizing: border-box;
  width: 81%;
  height: 80%;
  margin-left: 5%;
  margin-top: ${check_Tablet() ? "1.5em" : "0"};
  word-break: break-all;
  &:nth-child(n + 1):nth-child(-n + 4) {
    align-items: flex-start;
  }
`;
const TextChildAlign = styled.div`
  display: flex;
  flex-direction: row;
`;
const TextOverFlows = styled.div`
  width: 90%;
  display: block;
  word-wrap: break-word;
  word-break: keep-all;
  white-space: pre-wrap;
  word-break: break-all;
`;

const Technology = () => {
  const [barheight, setBarHeight] = useState(0);
  useEffect(() => {
    setBarHeight(document?.getElementById("tabbar")?.clientHeight * 1.1);
  }, []);

  return (
    <Parents id="technology">
      <BackgroundContents>
        <ChildTopBackground>
          <Text color="white" bold="bold" size="1.4em" lineHeight="1.5em">
            OUR TECHNOLOGY
          </Text>
          <Text color="rgba(255,255,255,0.7)" lineHeight="1.3em">
            4차 산업 기술과 석박사급 연구인력을 중심으로
            <br />
            다양한 ICT 분야에 대한 경험과 기술력을 보유하고 있습니다.
          </Text>
        </ChildTopBackground>
        <ChildBottomBackground>
          <ServiceParents>
            {is_Browser() ? (
              <Grid>
                {data.map((r, index) => (
                  <ServiceChild
                    key={`technology${index}`}
                    tablet={check_Tablet()}
                  >
                    <ServiceContents>
                      <Image src={r.image} />
                      <Text bold="bold" size="1.2em" margin="10px 0">
                        {r.title}
                      </Text>
                      <TextGroup>
                        {r.contents.map((r, j) => (
                          <TextChildAlign key={`technologyChild${j}`}>
                            <Text lineHeight="1.3em" size="1.2em">
                              -&nbsp;&nbsp;
                            </Text>
                            <Text
                              margin="5px 0 0 0"
                              color="#333333"
                              size="0.8em"
                              lineHeight="1.2em"
                              key={r.text}
                            >
                              {r.text}
                            </Text>
                          </TextChildAlign>
                        ))}
                      </TextGroup>
                    </ServiceContents>
                  </ServiceChild>
                ))}
              </Grid>
            ) : (
              <div className="example">
                {data.map((r, index) => (
                  <ServiceChild
                    key={`technologyie${index}`}
                    tablet={check_Tablet()}
                  >
                    <ServiceContents>
                      <Image src={r.image} />
                      <Text bold="bold" size="1.2em" margin="10px 0">
                        {r.title}
                      </Text>
                      {r.contents.map((r, j) => (
                        <TextOverFlows key={`technologeieChild${j}`}>
                          <Text
                            margin="5px 0 0 0"
                            color="#333333"
                            size="0.8em"
                            key={r.text}
                          >
                            {r.text}
                          </Text>
                        </TextOverFlows>
                      ))}
                    </ServiceContents>
                  </ServiceChild>
                ))}
              </div>
            )}
          </ServiceParents>
        </ChildBottomBackground>
      </BackgroundContents>
    </Parents>
  );
};
export default Technology;
