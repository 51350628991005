import styled from "styled-components";

export const Text = styled.span`
  font-size: ${(e) => e.size || "20px"};
  font-weight: ${(e) => e.bold || "0"};
  color: ${(e) => e.color || "black"};
  padding: ${(e) => e.padding || "0"};
  margin: ${(e) => e.margin || "0"};
  border-bottom: ${(e) => e.borderBottom || "0"};
  line-height: ${(e) => e.lineHeight || "1em"};
  cursor: ${(e) => e.cursor || "default"};
  letter-spacing: ${(e) => e.wordSpacing || "unset"};
  font-family: ${(e) => e.fontfamily || "NanumSquare"};
  text-decoration: ${(e) => e.textdeco || "none"};
`;
