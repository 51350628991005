import React, { useState } from "react";
import styled, { css } from "styled-components";
import { menutext } from "./data";
import { Text } from "../../../../Components/Components";
import { is_Mobile, useGetMobile, is_Browser } from "../../../../Util/Util";
import { animateScroll as scroll } from "react-scroll";

const Parents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
`;
const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(e) =>
    e.mobile
      ? css`
          width: 100%;
          word-break: break-all;
        `
      : css`
          white-space: nowrap;
        `}
`;
const Child = styled.div`
  ${is_Mobile()
    ? css`
        padding: 0.5em 0;
      `
    : css`
        padding: 1em 1em;
      `}
  height:100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  ${(e) =>
    e.border &&
    css`
      border-bottom: 4px solid #2f80ed;
    `}
  box-sizing: border-box;
`;

const Line = styled.div`
  background: #bdbdbd;
  height: 1.3em;
  width: ${is_Mobile() ? "2px" : "1.5px"};
  opacity: ${is_Mobile() ? "1" : "0.7"};
  ${!is_Mobile() &&
  css`
    margin: 0 1em;
  `}
  &:nth-last-child(1) {
    display: none;
  }
`;

const NewTopbar = ({ setSelect, select }) => {
  let textOption = {
    cursor: "pointer",
  };

  const moveFoodPlan = () => {
    let foodplanHeight = document.getElementById("foodPlanSlide").offsetTop;
    let tabbarHeight = document.getElementById("tabbar").offsetHeight;
    if (document.documentElement.scrollTop < foodplanHeight - tabbarHeight) {
      scroll.scrollTo(foodplanHeight - tabbarHeight);
    }
  };

  return (
    <Parents mobile={useGetMobile()}>
      <Contents mobile={useGetMobile()}>
        {menutext.map((r) => (
          <>
            <Child
              key={r.id}
              border={r.id === select}
              onClick={() => {
                setSelect(r.id);
                moveFoodPlan();
              }}
            >
              {is_Mobile() && (r.id === 3 || r.id === 4) ? (
                r.mobile.map((f, index) => (
                  <Text
                    {...textOption}
                    size="1em"
                    color={r.id === select ? "#2f80ed" : ""}
                    bold={r.id === select && "bold"}
                    lineHeight="1.3em"
                  >
                    {f.text}
                    <br />
                  </Text>
                ))
              ) : (
                <Text
                  {...textOption}
                  size="1em"
                  color={r.id === select ? "#2f80ed" : ""}
                  bold={r.id === select && "bold"}
                >
                  {r.name}
                </Text>
              )}
            </Child>
            <Line />
          </>
        ))}
      </Contents>
    </Parents>
  );
};
export default NewTopbar;
