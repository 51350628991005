export const slidedata = [
  {
    title: [
      {
        text: "내 시간을 존중하는 직장",
      },
    ],
    image: require("../../image/slide1.svg").default,
    contents: [
      {
        text: "탄력근무제를 통해 생활과 업무의 균형을 추구합니다.",
      },
      {
        text: "휴식이 필요하다면 쉬어야죠. 눈치보지 말고 휴가 다녀오세요.",
      },
    ],
  },
  {
    title: [
      { text: "배려하고, 인정하는" },
      {
        text: "수평적 커뮤니케이션",
      },
    ],
    image: require("../../image/slide2.svg").default,
    contents: [
      {
        text: "지시보단 제안을, 질책보단 칭찬을",
      },
      {
        text: "다른 직원을 배려하고 인정하는 문화를 지향합니다.",
      },
    ],
  },
  {
    title: [{ text: "내 일에 집중할 수 있는" }, { text: "최고의 업무 환경" }],
    image: require("../../image/slide3.svg").default,
    contents: [
      {
        text: "가장 편하게 일할 수 있는 업무 환경을 제공합니다.",
      },
      {
        text: "트렌디한 업무 플랫폼을 통해 업무를 효율적으로 개선합니다.",
      },
    ],
  },
  {
    title: [{ text: "배울수 있는 회사" }, { text: "키워나가는 역량" }],
    image: require("../../image/slide4.svg").default,
    contents: [
      {
        text: "직원의 역량이 회사의 역량입니다.",
      },
      {
        text: "기술 세미나, 스터디를 통해 능력을 키우고",
      },
      {
        text: "로드맵 공유 및 가이드를 통해 업무 노하우를 공유합니다.",
      },
    ],
  },
];
