export const menutext = [
  {
    id: 0,
    name: "직매장",
  },
  {
    id: 1,
    name: "급식센터",
  },
  {
    id: 2,
    name: "기획생산",
  },
  {
    id: 3,
    name: "강력한 편의기능",
    mobile: [{ text: "강력한" }, { text: "편의기능" }],
  },
  {
    id: 4,
    name: "모바일 서비스",
    mobile: [{ text: "모바일" }, { text: "서비스" }],
  },
];

export const foodplan = [
  {
    id: 0,
    smalltitle: "로컬푸드 10년의 노하우를 결집한",
    bigtitle: "직매장 운영 시스템",
    image: require("../../image/directstore.jpg"),
    info: [
      {
        text: "농가 및 상품 관리, 판매 및 정산 등 로컬푸드에 특화된 매장 관리 기능 ",
      },
      {
        text: "안전하고, 믿을 수 있는 먹거리를 위한 교육 및 인증 관리 기능",
      },
      {
        text: "재고관리, 이벤트 기능 등 로컬푸드 매장 운영에 도움이 되는 부가 기능들",
      },
    ],
  },
  {
    id: 1,
    smalltitle: "국내 유일 로컬푸드 기반",
    bigtitle: "급식센터 운영 시스템",
    image: require("../../image/cateringcenter.png"),
    info: [
      {
        text: "학교급식과 공공급식 뿐만아니라, 도농상생 급식까지 모든 급식에서 운영 가능",
      },
      {
        text: "농가 · 상품 관리에서 발주, 수주까지, 간편하게 개선한 수발주 관리 기능",
      },
      {
        text: "발주 및 클레임을 등록 · 관리할 수 있는 온라인 주문 시스템",
      },
      {
        text: "유통 단계의 추가에도 유연하게 대응할수 있는 수주 위임 기능",
      },
    ],
  },
  {
    id: 2,
    smalltitle: "성공적인 푸드플랜을 위한 통합데이터 기반",
    bigtitle: "기획생산 시스템",
    image: require("../../image/plannedproduction.png"),
    info: [
      {
        text: "직매장 및 급식센터 정보를 하나로 통합하고, 정보 분석 도구를 이용한 소비 분석 기능",
      },
      {
        text: "경작 현황에서 예상 생산량 · 출하량까지 한 흐름으로 관리하는 기획 생산 기능",
      },
      {
        text: "국내 최다 농산물 유통 채널 “농협”과 연계 서비스 예정",
      },
      {
        text: "여러 지역 푸드플랜들이 하나로 연결된 광역 푸드플랜 서비스 예정",
      },
    ],
  },
  {
    id: 3,
    smalltitle: "복잡한 운영 자료들도 클릭만으로 만들어내는",
    bigtitle: "분석 & 보고 서비스",
    image: require("../../image/dataanalysis.png"),
    info: [
      {
        text: "서식를 원하는 형태로 구성하고 필요한 정보만 추출하는 정보 분석 기능",
      },
      {
        text: "클릭 한번으로 미리 만든 서식을 적용하고, 내 서식을 공유할 수 있는 서식 관리 기능",
      },
      {
        text: "보이는 화면 그대로 엑셀로 내보낼 수 있는 외부 공유 기능",
      },
      {
        text: "도표와 그래프로 보기 쉽게 표현하는 시각화 기능 지원 예정",
      },
    ],
  },
  {
    id: 4,
    smalltitle: "언제든지, 어디서든지",
    bigtitle: "모바일 서비스",
    image: require("../../image/mobileservice.png"),
    info: [
      {
        text: "사무실 밖에서도 업무를 진행할 수 있는 매니저용 APP",
      },
      { text: "실시간으로 판매 & 수주 정보를 조회할 수 있는 농가 APP" },
      {
        text: "로컬푸드 활용을 돕는 소비자용 APP 지원 예정",
      },
    ],
  },
];
