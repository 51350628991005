import React, { useEffect } from "react";
import { is_Mobile } from "./Util/Util";
import Router from "./Router/Router";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const App = () => {
  const urlCheck = () => {
    let location = window.location;
    if (location.protocol == "http:" && location.hostname != "localhost") {
      location.href = location?.origin?.replace(/^http/, "https");
    }
  };

  useEffect(() => {
    urlCheck();
  }, []);

  return (
    <Container>
      <Router />
    </Container>
  );
};

export default App;
