export const images = {
  logo: require("./image/logo.svg").default,
  logo_transparent: require("./image/logo_transparent.svg").default,
  mobilelogo: require("./image/mobileLogo.svg").default,
  topimage: require("./image/topimage.jpg"),
  foodplan: require("./image/foodplan.svg").default,
  market: require("./image/market.jpg"),
  slide_button: require("./image/leftbutton.svg").default,
  technology: require("./image/technology.svg").default,
  technology_Mobile: require("./image/technology_Mobile.svg").default,
  menu: require("./image/menu.svg").default,
  menu_transparent: require("./image/menu_transparent.svg").default,
  exit: require("./image/exit.svg").default,
};
