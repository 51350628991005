import React from "react";
import styled, { css } from "styled-components";
import { images } from "../../Images";
import { Text } from "../../../../Components/Components";
import { data } from "./data";

const Parents = styled.div`
  width: 100%;
  height: 180vh;
  height: calc(var(--vh, 1vh) * 180);
  background: url(${images.technology}) no-repeat;
  background-size: cover;
  background-position-x: 25%;
`;
const BackgroundContents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(54, 74, 94, 0.4);
`;
const ChildBottomBackground = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background: rgba(55, 74, 94, 0.6);
`;
const TopContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
`;
const TextGroup = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;
const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:nth-last-child(1) {
    height: 120%;
  }
  &:nth-last-child(1) > div > img {
    width: 23%;
    height: 23%;
  }
`;
const Child = styled.div`
  display: flex;
  width: 80%;
  height: 85%;
  flex-direction: column;
  justify-content: center;
  background: white;
  border-radius: 10px;
  align-items: center;
`;
const Image = styled.img`
  width: 25%;
  height: 25%;
`;
const BottomTextGroup = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
`;
const TextAlign = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const TechnologyMobile = () => {
  return (
    <Parents id="mobiletechnology">
      <BackgroundContents>
        <TopContents>
          <TextGroup>
            <Text color="white" bold="bold" size="1.3em" lineHeight="1.5em">
              OUR TECHNOLOGY
            </Text>
            <Text color="rgba(255,255,255,0.7)" size="0.9em" lineHeight="1.3em">
              4차 산업 기술과 석박사급 연구개발인력을 중심으로
              <br />
              다양한 ICT 기술 분야에 대한 경험과 기술력을 <br />
              보유하고 있습니다.
            </Text>
          </TextGroup>
        </TopContents>
        <ChildBottomBackground>
          {data.map((r, index) => (
            <Contents key={`technologymobile${index}`}>
              <Child>
                <Image src={r.image} />
                <Text bold="bold" size="1.2em" margin="10px 0">
                  {r.mobiletitle || r.title}
                </Text>
                <BottomTextGroup>
                  {r.contents.map((f, index) => (
                    <TextAlign key={`techTextmobile${index}`}>
                      <Text size="0.9em" lineHeight="1.3em">
                        -&nbsp;
                      </Text>
                      <Text size="0.9em" lineHeight="1.3em">
                        {f.text}
                      </Text>
                    </TextAlign>
                  ))}
                </BottomTextGroup>
              </Child>
            </Contents>
          ))}
        </ChildBottomBackground>
      </BackgroundContents>
    </Parents>
  );
};
export default TechnologyMobile;
