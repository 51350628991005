import React, { useState } from "react";
import styled, { css } from "styled-components";
import TopMenuBar from "./TopMenuBar";
import Slide from "./Slide";
import { check_Tablet, useGetMobile } from "../../../../Util/Util";
import MobileSlide from "./MobileSlide";
import NewTopbar from "./NewTopbar";

const Parents = styled.div`
  width: 100%;
  height: 70vh;
  padding-top: ${check_Tablet() ? "1.5em" : "2em"};
  height: calc(var(--vh, 1vh) * 70);
  ${(e) =>
    e.mobile &&
    css`
      min-height: calc(var(--vh, 1vh) * 85);
    `}
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Market = () => {
  const [select, setSelect] = useState(0);
  const setMenu = (n) => {
    setSelect(n);
  };
  return (
    <Parents mobile={useGetMobile()} id="foodPlanSlide">
      {/* <TopMenuBar setSelect={setMenu} select={select} /> */}
      <NewTopbar setSelect={setMenu} select={select} />
      {useGetMobile() ? (
        <MobileSlide setSelect={setMenu} select={select} />
      ) : (
        <Slide setSelect={setMenu} select={select} />
      )}
    </Parents>
  );
};
export default Market;
