import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "../../../../Components/Components";
import { foodplan } from "./data";
import Swipe from "react-easy-swipe";
import { isTablet } from "react-device-detect";
import MobileSlideItem from "./SlideChild/MobileSlideItem";

const Parents = styled.div`
  width: 90%;
  min-height: 100%;
  margin-top: 5%;
  overflow: hidden;
`;
const Contents = styled.div`
  width: 100%;
  min-height: 100%;
  display: inline-block;
  white-space: nowrap;
  transition: transform ${(e) => (e.swipe ? "0.5s" : "0s")};
  transform: translateX(${(e) => e.positionx});
`;
export const BottomBlack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(to top, black 1%, transparent 30%);
`;
export const ImageParents = styled.div`
  position: relative;
  width: 95%;
  min-height: 230px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  background: url(${(e) => e.image}) no-repeat;
  background-size: cover;
  background-position: ${isTablet ? "left center" : "center center"};
`;
export const Child = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const ChildWidth = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const TextGroup = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  display: flex;
  flex-direction: column;
`;
export const TextInfo = styled.div`
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Rows = styled.div`
  display: flex;
  width: 95%;
  flex-direction: row;
  overflow: hidden;
  /* word-break: keep-all; */
  white-space: pre-wrap;
  white-space: normal;
`;

const MobileSlide = ({ setSelect, select }) => {
  const [positionX, setPositionX] = useState(0);
  const [swipe, setSwipe] = useState(true);

  const lockY = (state) => {
    let container = document.getElementById("move");
    if (state) {
      container.addEventListener(
        "scroll",
        (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        },
        {
          passive: false,
        }
      );
      container.style.touchAction = "pan-x";
    } else {
      container.style.touchAction = "pan-y";
    }
  };

  const onSwipeMove = (position) => {
    setSwipe(false);
    if (position.x < -20) {
      setPositionX(position.x);
      return;
    } else if (position.x > 20) {
      setPositionX(position.x);
      return;
    }
  };

  const onSwipeEnd = (position) => {
    if (positionX < -40) {
      if (select >= 4) {
        setSelect(0);
      } else {
        setSelect(select + 1);
      }
    }
    if (positionX > 40) {
      if (select == 0) {
        setSelect(4);
      } else {
        setSelect(select - 1);
      }
    }
    setTimeout(() => {
      lockY(false);
    }, 1000);
    setPositionX(0);
    setSwipe(true);
  };
  const returnUnderLine = (t) => {
    let baseText = "";

    if (t.indexOf("서비스 예정") > -1) {
      baseText = "서비스 예정";
    } else if (t.indexOf("지원 예정") > -1) {
      baseText = "지원 예정";
    }
    return (
      <Text color="#474747" size="1.1em" margin="0 0 15px 0" lineHeight="1.2em">
        {t.toString().replace(baseText, "")}
        <Text size="1em" textdeco="underline">
          {baseText}
        </Text>
      </Text>
    );
  };

  return (
    <Parents>
      <Swipe onSwipeMove={onSwipeMove} onSwipeEnd={onSwipeEnd}>
        <Contents
          id="move"
          positionx={`calc( ${-100 * select}%)`}
          swipe={swipe}
        >
          <MobileSlideItem />
        </Contents>
      </Swipe>
    </Parents>
  );
};
export default MobileSlide;
