import React from "react";
import styled, { css } from "styled-components";
import { Text } from "../../../../Components/Components";
import { useGetMobile } from "../../../../Util/Util";
import Slide from "./Slide";
import MobileSlide from "./MobileSlide";

const Parents = styled.div`
  width: 100%;
  height: 80vh;
  ${(e) =>
    e.mobile
      ? css`
          height: calc(var(--vh, 1vh) * 90);
        `
      : css`
          hegiht: calc(var(--vh, 1vh) * 80);
        `};
  display: flex;
  justify-content: center;
  padding-top: 1.5em;
  /* align-items: center; */
`;

const OurCuture = () => {
  return (
    <Parents mobile={useGetMobile()} id="ourCuture">
      {useGetMobile() ? <MobileSlide /> : <Slide />}
    </Parents>
  );
};
export default OurCuture;
