import React, { useEffect } from "react";
import styled from "styled-components";
import { Map, MapMarker, CustomOverlayMap } from "react-kakao-maps-sdk";
import { Text } from "../../../../Components/Components";
import { useGetMobile, check_Tablet, is_Mobile } from "../../../../Util/Util";

const Parents = styled(Map)`
  width: ${(e) => (e.mobile ? "90%" : "66%")};
  height: ${(e) =>
    e.mobile
      ? "calc(var(--vh, 1vh)*30)"
      : check_Tablet()
      ? "calc(var(--vh, 1vh)*45)"
      : "calc(var(--vh, 1vh)*55)"};
  margin: 2% 0;
  box-sizing: border-box;
  border-radius: 10px;
  /* @media screen and (max-height: 785px) {
    height: calc(var(--vh, 1vh) * 35) !important;
  } */
  @media screen and (max-width: 1390px) {
    height: calc(var(--vh, 1vh) * 45);
  }
`;
const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const TextContents = styled.div`
  border-radius: 5px;
  background: white;
  padding: 5px 10px;
`;
const Image = styled.img`
  width: 40px;
  height: 40px;
`;
const IEParents = styled.div`
  width: 66%;
  height: 50%;
  border-radius: 10px;
`;

export const KaKaoMap = ({ name }) => {
  return (
    <Parents
      center={{ lat: 35.824147314880506, lng: 127.16457147054257 }}
      mobile={is_Mobile()}
      level={useGetMobile() ? 4 : 3}
    >
      <CustomOverlayMap
        position={{ lat: 35.824147314880506, lng: 127.16457147054257 }}
      >
        <Marker>
          <TextContents>
            <Text size="0.9em">{name}</Text>
          </TextContents>
          <Image src={require("../../image/location.svg").default} />
        </Marker>
      </CustomOverlayMap>
    </Parents>
  );
};

export const IEKaKaoMap = ({ name }) => {
  const { kakao } = window;
  useEffect(() => {
    Maps();
  }, []);
  window.addEventListener("resize", () => {
    Maps();
  });
  const Maps = () => {
    let container = document.getElementById("iemap");
    let options = {
      center: new kakao.maps.LatLng(35.824147314880506, 127.16457147054257),
      level: 3,
    };
    let map = new kakao.maps.Map(container, options);
    var markerImage = new kakao.maps.MarkerImage(
        require("../../image/location.svg").default,
        new kakao.maps.Size(50, 50),
        new kakao.maps.Point(27, 69)
      ),
      markerPosition = new kakao.maps.LatLng(
        35.824147314880506,
        127.16457147054257
      ); // 마커가 표시될 위치입니다
    let marker = new kakao.maps.Marker({
      position: markerPosition,
      image: markerImage,
    });
    // let infowindow = new kakao.maps.InfoWindow({
    //   content: `<div class="customes" style="display:block;text-align:center;border:none;border-radius:5px;padding:10px;font-size:0.8em;">${name}</div>`,
    // });
    // infowindow.open(map, marker);
    marker.setMap(map);
    let content = `<div class="customOverlay" style="display:block; position:relative;bottom:70px;text-align:center;border:none;border-radius:5px;padding:10px;font-size:0.8em;background:white;">${name}</div>`;
    // let parents = document.getElementById("customes");
    // parents.style.borderWdith = "0";
    // parents.style.backgroundColor = "red";
    var customOverlay = new kakao.maps.CustomOverlay({
      map: map,
      position: markerPosition,
      content: content,
      yAnchor: 1,
    });
  };
  return (
    <IEParents
      id="iemap"
      onWheel={(e) => {
        if (e.target !== e.currentTarget) return e.stopPropagation();
      }}
    />
  );
};
